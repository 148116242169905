import index from '../views/index.vue'
import Home from '../views/HomePage.vue'
import Consultation from '../views/ConsultationPage.vue'
import Resources from '../views/ResourcesPage.vue'
import SignIn from '../views/SignIn.vue'
import SignUp from '../views/SignUp.vue'
import SystemChecker from '../../../components/consultation-layout/systemChecker.vue'
import ResultAnalysis from '../../../components/consultation-layout/resultAnalysis.vue'


export default [
  {
    path: '/',
    component: index,
    children: [
      {
        path: '',
        alias: '/home',
        name: 'HomePage',
        component: Home
      },
      {
        path: '',
        alias: '/consultation',
        name: 'ConsultationPage',
        component: Consultation
      },
      {
        path: '',
        alias: '/system-checker',
        name: 'system-checker',
        component: SystemChecker
      },
       {
        path: '',
        alias: '/result-analysis',
        name: 'ResultAnalysisPage',
        component: ResultAnalysis
      },
      {
        path: '',
        alias: '/resources',
        name: 'Resources',
        component: Resources
      },
      {
        path: '',
        alias: '/sign-in',
        name: 'SignInPage',
        component: SignIn
      },
      {
        path: '',
        alias: '/sign-up',
        name: 'SignUpPage',
        component: SignUp
      },
    ]
  }
]
