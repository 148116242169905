<template>
  <section>
    <!-- <heroSection />
    <heroSubSection />
    <sectionOne />
    <sectionTwo />
    <sectionThree />
    <sectionFour />
    <sectionFive /> -->
   <div class="p-5">
    <h1>Page Under Construction</h1>
   </div>
  </section>
</template>

<script>
import "../../../styles/home.scss";
import { defineComponent } from "vue";
// import heroSection from "../../../components/home-layout/heroSection.vue";
// import heroSubSection from "../../../components/home-layout/heroSub.vue";
// import sectionOne from "../../../components/home-layout/sectionOne.vue";
// import sectionTwo from "../../../components/home-layout/sectionTwo.vue";
// import sectionThree from "../../../components/home-layout/sectionThree.vue";
// import sectionFour from "../../../components/home-layout/sectionFour.vue";
// import sectionFive from "../../../components/home-layout/sectionFive.vue";

export default defineComponent({
  name: "Home-Page",
  components: {
    // heroSection,
    // heroSubSection,
    // sectionOne,
    // sectionTwo,
    // sectionThree,
    // sectionFour,
    // sectionFive,
  },
  data() {
    return {};
  },
});
</script>
