<template>
  <div>
    <!-- <my-header /> -->
    <!-- <main class="pb-5"> -->
    <router-view />
    <!-- </main> -->
    <!-- <my-footer /> -->
  </div>
</template>

<script>
// import MyHeader from '@/components/layouts/MyHeader.vue';
// import MyFooter from '@/components/layouts/MyFooter.vue';

export default {
  name: "BaseHome",
  components: {
    // MyHeader,
    // MyFooter,
  },
};
</script>

<style lang="sass" scoped></style>
