import axiosInstance from "../plugins/AI";
import ApiInstance from "@/plugins/AXIOS";


export default class ApiService {
  
  async getDiagnosis(payload) {
    const data = await axiosInstance.post("/e4e/ask/", payload);
    return data;
  }
  async savePatient(payload){
    const data = await ApiInstance.post("patients/patient_profile/", payload);
    return data;
  }
}
