<template>
  <div class="container">
    <div class="row pt-5 justify-content-center">
      <div class="col-md-6 col-sm-6 sign-in p-5">
        <img src="../../../assets/logo.png" class="sign-in-logo" />
        <h2 class="my-5">Doctor Sign In</h2>
        <form>
          <div class="form-group">
            <label for="formGroupExampleInput">Doctor ID</label>
            <input
              type="text"
              class="form-control mb-3 p-2"
              id="formGroupExampleInput"
            />
          </div>
          <div class="form-group">
            <label for="formGroupExampleInput2">Password</label>
            <input
              type="text"
              class="form-control mb-3 p-2"
              id="formGroupExampleInput2"
            />
          </div>
          <p>Forgot Password?</p>
          <button
            type="button"
            class="btn btn-secondary d-block p-2 w-25 mx-auto mb-3"
          >
            Sign In
          </button>
          <p class="text-center">
            Don't have an account?
            <router-link to="/sign-up">Sign Up</router-link>
          </p>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped>
@import "../../../styles/home.scss";
/* You can keep component-specific styles here, if needed */
/* .vl {
  border-left: 2px solid grey;
} */
</style>
