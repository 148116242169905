<template>
  <div class="text-center">
    <div class="resource-header p-sm-5">
      <h2 class="p-3">Resources</h2>
      <p class="p-3">
        Stay updated with WHO guidelines and delve into our comprehensive
        medical thesaurus.
      </p>
      <div class="row pt-3" style="justify-content">
        <div class="col-md-7 col-sm-12 search-bar">
          <input
            v-model="searchQuery"
            placeholder="Search"
            @input="handleSearchInput"
            class="p-2 search-input"
          />
          <span class="search-icon" @click="search">
            <i class="bi bi-search"></i>
          </span>
        </div>
        <div class="col-md-4 col-sm-12 search-bar">
          <div class="select-wrapper">
            <label for="relevance">Filter by:</label>
            <!-- <div class="select-container"> -->
            <select
              v-model="selectedRelevance"
              id="relevance"
              class="form-select search-input p-2"
            >
              <option value="relevance">Relevance</option>
              <option value="date">Date</option>
              <!-- Add more relevance options as needed -->
            </select>
            <i class="bi bi-caret-down select-icon"></i>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>

    <!-- Display search message -->
    <div v-if="filteredCards.length === 0" class="search-message">
      No results found for "{{ searchQuery }}"
    </div>

    <!-- <input v-model="searchQuery" placeholder="Search Cards" /> -->
    <div>
      <h2 class="p-4">Articles</h2>
      <div class="card-list text-center">
        <div class="card" v-for="card in paginatedCards" :key="card.id">
          <img :src="card.imageSrc" alt="Card Image" class="card-image" />
          <p class="mt-4">
            <b>{{ card.description }}</b>
          </p>
          <p class="card-body">{{ card.body }}</p>
        </div>
        <div class="pagination">
          <button
            @click="setCurrentPage(currentPage - 1)"
            :disabled="currentPage === 1"
          >
            <i class="bi bi-caret-left"></i>
          </button>
          <span> Page {{ currentPage }} of {{ totalPages }} </span>
          <button
            @click="setCurrentPage(currentPage + 1)"
            :disabled="currentPage === totalPages"
          >
            <i class="bi bi-caret-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedRelevance: "relevance",
      cards: [
        {
          id: 1,
          imageSrc: require("../../../assets/imageBg.png"),
          description: "WHO Guidelines for Malaria",
          body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua  dolor sit amet...",
        },
        {
          id: 2,
          imageSrc: require("../../../assets/imageBg.png"),
          description: "WHO Guidelines for HIV",
          body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua  dolor sit amet...",
        },
        {
          id: 3,
          imageSrc: require("../../../assets/imageBg.png"),
          description: "WHO Guidelines for Cough",
          body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua  dolor sit amet...",
        },
        {
          id: 4,
          imageSrc: require("../../../assets/imageBg.png"),
          description: "WHO Guidelines for Fever",
          body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua  dolor sit amet...",
        },
        {
          id: 5,
          imageSrc: require("../../../assets/imageBg.png"),
          description: "WHO Guidelines for Diabetes",
          body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua  dolor sit amet...",
        },
        {
          id: 6,
          imageSrc: require("../../../assets/imageBg.png"),
          description: "WHO Guidelines for Typhoid",
          body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua  dolor sit amet...",
        },
        {
          id: 7,
          imageSrc: require("../../../assets/imageBg.png"),
          description: "WHO Guidelines for Headaches",
          body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua  dolor sit amet...",
        },
        {
          id: 8,
          imageSrc: require("../../../assets/imageBg.png"),
          description: "WHO Guidelines for Cancer",
          body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua  dolor sit amet...",
        },
        {
          id: 9,
          imageSrc: require("../../../assets/imageBg.png"),
          description: "WHO Guidelines for Malaria",
          body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua  dolor sit amet...",
        },
        {
          id: 10,
          imageSrc: require("../../../assets/imageBg.png"),
          description: "WHO Guidelines for Cancer",
          body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua  dolor sit amet...",
        },
        {
          id: 11,
          imageSrc: require("../../../assets/imageBg.png"),
          description: "WHO Guidelines for Fever",
          body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua  dolor sit amet...",
        },
        {
          id: 12,
          imageSrc: require("../../../assets/imageBg.png"),
          description: "WHO Guidelines for Cough",
          body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua  dolor sit amet...",
        },
        // Add more card data here
      ],
      searchQuery: "",
      currentPage: 1,
      itemsPerPage: 9,
    };
  },
  computed: {
    filteredCards() {
      const query = this.searchQuery.toLowerCase().trim();
      if (!query) return this.cards;

      return this.cards.filter((card) =>
        card.description.toLowerCase().includes(query)
      );
    },
    totalPages() {
      return Math.ceil(this.filteredCards.length / this.itemsPerPage);
    },
    paginatedCards() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredCards.slice(start, end);
    },
  },
  methods: {
    setCurrentPage(page) {
      this.currentPage = page;
    },
  },
};
</script>

<style scoped>
/* Add your component's styles here */
.card {
  /* Card styles */
  margin: 10px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: inline-block;
}

.card-image {
  /* Set image styles to fill the entire card */
  width: 100%;
  height: 100%;
  object-fit: cover; /* This will make the image cover the entire card while maintaining its aspect ratio */
}

.card-body {
  font: 10px !important;
}

.pagination {
  /* Pagination styles */
  margin-top: 20px;
  text-align: center;
}

.pagination button {
  border: none !important;
}

.active {
  /* Active page button styles */
  font-weight: bold;
  background-color: #007bff;
  color: #fff;
  border: 1px solid #007bff;
}

.search-bar {
  position: relative;
}

.search-input {
  border: 1px solid grey;
  border-radius: 20px;
  background: #ccc;
  width: 60%;
}

.search-icon {
  position: absolute;
  right: 180px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.select-wrapper {
  display: flex;
  align-items: center;
}

.select-icon {
  position: absolute;
  right: 110px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

/* Adjust label styles as needed */
label {
  margin-right: 25px; /* Add spacing between label and select */
}

@media screen and (max-width: 990px) {
  .search-input {
    margin: 5px;
  }

  .search-icon {
    right: 110px;
  }
  label {
    margin-right: 15px; /* Add spacing between label and select */
  }
}
</style>
