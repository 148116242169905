<template>
  <div class="navbar-wrapper">
    <nav class="navbar navbar-expand-lg navbar-light bg-light p-3">
      <!-- Logo on the left -->
      <a class="navbar-brand mx-md-5 mx-sm-1" href="#">
        <img
          src="../../assets/logo.png"
          alt="Logo"
          width="35"
          class="d-inline-block align-top"
        />
        LOGO
      </a>

      <!-- Hamburger menu button (visible on mobile) -->
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <!-- Navigation links on the right -->
      <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item mx-3">
            <router-link to="/home" class="nav-link" href="#">
              <small>Home</small>
            </router-link>
          </li>
          <li class="nav-item mx-3">
            <router-link to="/consultation" class="nav-link" href="#">
              <small>Consultations</small></router-link
            >
          </li>
          <li class="nav-item mx-3">
            <a class="nav-link" href="#"> <small>Simulation Lab</small> </a>
          </li>
          <li class="nav-item mx-3">
            <router-link to="/resources" class="nav-link" href="#">
              <small>Resources</small></router-link
            >
          </li>
          <div class="vl"></div>
          <li class="nav-item mx-3">
            <router-link to="/sign-in" class="nav-link" href="#"
              ><small>Sign in</small></router-link
            >
          </li>
          <button type="button" class="btn btn-secondary mx-3">
            Get started
          </button>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "NavbarComponent",

  // methods: {
  //   addNavBar() {
  //     var x = document.getElementById("navbarTogglerDemo02");
  //     x.classList.remove("removeNavBar");
  //   },
  //   RemoveNavBar() {
  //     var x = document.getElementById("navbarTogglerDemo02");
  //     x.classList.add("removeNavBar");
  //   },
  // },
};
</script>

<style scoped>
@import "./style.scss";
/* You can keep component-specific styles here, if needed */
.vl {
  border-left: 2px solid grey;
}
.navbar-wrapper {
  position: sticky;
  top: 0;
  z-index: 100; /* You can adjust the z-index as needed */
}
</style>
