<template>
  <div>
    <my-header />
    <main>
      <router-view />
    </main>
    <my-footer />
  </div>
</template>

<script>
import MyHeader from "@/components/layouts/HeaderSection.vue";
import MyFooter from "@/components/layouts/FooterSection.vue";

export default {
  name: "App",
  components: {
    MyHeader,
    MyFooter,
  },
};
</script>

<style lang="scss" scoped>
main {
  background-color: #f0f0f0;
  min-height: calc(100vh - 70px);
}
</style>
