<template>
  <div class="container footer p-5">
    <div class="row mt-md-5 justify-content-between">
      <div class="col-md-6 col-sm-12">
        <h3>LOGO</h3>
        <p class="pr-3">
          Ingenious is your AI powered doctors' toolbox, gathering vital tools
          at their fingertips for seamless care!
        </p>
        <img src="../../assets/socials.png" class="mt-4" />
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="row">
          <div class="col">
            <ul>
              <li>Solutions</li>
              <li>Consultations</li>
              <li>Simulations Lab</li>
              <li>Resources</li>
            </ul>
          </div>
          <div class="col">
            <ul>
              <li>Company</li>
              <li>Brand</li>
              <li>Featured</li>
              <li>Partnership</li>
              <li>Articles</li>
            </ul>
          </div>
          <div class="col">
            <ul>
              <li>Support</li>
              <li>Get Help</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-md-8">
        <p>©2023 Built with vue3 by eHealth4everyone.</p>
      </div>
      <div class="col-md-2">
        <p>Privacy & Policy</p>
      </div>
      <div class="col-md-2">
        <p>Terms & Conditions</p>
      </div>
    </div>
  </div>
</template>
