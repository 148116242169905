<template>
  <div class="container">
    <div class="row pt-md-5 justify-content-center">
      <div class="col-md-8 col-sm-6 sign-in p-md-5 p-sm-4 m-md-4">
        <img src="../../../assets/logo.png" class="sign-in-logo" />
        <h2 class="my-5">Doctor Sign Up</h2>
        <form>
          <div class="row">
            <div class="col p-3">
              <label>Doctor ID</label>
              <input type="text" class="form-control" />
            </div>
            <div class="col p-3">
              <label>Other Names</label>
              <input type="text" class="form-control" />
            </div>
          </div>
          <div class="row">
            <div class="col p-3">
              <label>Email Address</label>
              <input type="text" class="form-control" />
            </div>
            <div class="col p-3">
              <label>Gender</label>
              <input type="text" class="form-control" />
            </div>
          </div>
          <div class="row">
            <div class="col p-3">
              <label>Hospital</label>
              <input type="text" class="form-control" />
            </div>
            <div class="col p-3">
              <label>Doctor ID</label>
              <input type="text" class="form-control" />
            </div>
          </div>
          <div class="row">
            <div class="col p-3">
              <label>Phone Number</label>
              <input type="text" class="form-control" />
            </div>
            <div class="col p-3">
              <label>Specialization</label>
              <input type="text" class="form-control" />
            </div>
          </div>
          <div class="row">
            <div class="col p-3">
              <label>Password</label>
              <input type="text" class="form-control" />
            </div>
            <div class="col p-3">
              <label>Confirm Password</label>
              <input type="text" class="form-control" />
            </div>
          </div>

          <button
            type="button"
            class="btn btn-secondary d-block p-2 w-25 mx-auto mb-3 mt-5"
          >
            Sign In
          </button>
          <p class="text-center">
            Already have an account?
            <router-link to="/sign-in">Sign In</router-link>
          </p>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped>
@import "../../../styles/home.scss";
/* You can keep component-specific styles here, if needed */
/* .vl {
  border-left: 2px solid grey;
} */
</style>
