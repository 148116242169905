<template>
  <section>
    <div @click="goBack()" class="back-wrap">
      <img src="../../assets/consultation-icons/back.svg" width="60" />
      Home
    </div>
    <div
      v-if="selectedConsultation === null"
      class="container text-center content-padding"
    >
      <h1 class="p-4 page-title">Consultations</h1>
      <div class="row text-center content-wrap">
        <div class="col-md-4 col-sm-12 text-center">
          <img src="../../assets/consultation-icons/Heart.svg" width="60" />
          <button
            type="button"
            class="btn btn-secondary d-block py-1 w-auto mt-3 mx-auto px-3"
          >
            <!-- <h1 style="color: white; font-size: 25px; text-decoration: none">
              Primary Medical Care
            </h1> -->
            <router-link
              to="system-checker"
              style="color: white; font-size: 25px; text-decoration: none"
            >
              Primary Medical Care
            </router-link>
          </button>
          <p class="py-4 px-5">
            Enter patient symptoms and uncover top matches tailored to those
            symptoms.
          </p>
        </div>
        <div class="col-md-4 col-sm-12">
          <img src="../../assets/consultation-icons/Record.svg" width="50" />
          <!-- <button
            type="button"
            class="btn btn-secondary d-block py-1 mt-3 mx-auto px-3"
          >
            <router-link
              to="system-checker"
              style="color: white; font-size: 25px; text-decoration: none"
            >
              Maternal Care & Family Planning</router-link
            >
          </button> -->
          <button
            type="button"
            class="btn btn-secondary d-block py-1 w-auto mt-3 mx-auto px-3"
          >
            <h1 style="color: white; font-size: 25px; text-decoration: none">
              Maternal Care & Family Planning
            </h1>
          </button>
          <p class="p-4">
            Receive a comprehensive analysis based on your chosen match.
          </p>
        </div>
        <div class="col-md-4 col-sm-12">
          <img src="../../assets/consultation-icons/Record.svg" width="50" />
          <!-- <button
            type="button"
            class="btn btn-secondary d-block py-1 w-auto mt-3 mx-auto px-3"
          >
            <router-link
              to="system-checker"
              style="color: white; font-size: 25px; text-decoration: none"
            >
              Immunization</router-link
            >
          </button> -->
          <button
            type="button"
            class="btn btn-secondary d-block py-1 w-auto mt-3 mx-auto px-3"
          >
            <h1 style="color: white; font-size: 25px; text-decoration: none">
              Immunization
            </h1>
          </button>
          <p class="p-4">Discover the interactions between various drugs.</p>
        </div>
      </div>
    </div>
    <div
      v-if="selectedConsultation === 'Primary Medical Care'"
      class="container text-center content-padding"
    >
      <h1 class="p-4 page-title">{{ selectedConsultation }}</h1>
      <div class="row text-center content-wrap">
        <div class="col-md-4 col-sm-12 text-center">
          <img src="../../assets/consultation-icons/Heart.svg" width="60" />
          <button
            type="button"
            class="btn btn-secondary d-block py-1 w-auto mt-3 mx-auto px-5"
          >
            <router-link
              to="system-checker"
              style="color: white; font-size: 25px; text-decoration: none"
            >
              Diagnosis
            </router-link>
          </button>
          <p class="py-4 px-5">
            Enter patient symptoms and uncover top matches tailored to those
            symptoms.
          </p>
        </div>
        <div class="col-md-4 col-sm-12">
          <img src="../../assets/consultation-icons/Record.svg" width="50" />
          <button
            type="button"
            class="btn btn-secondary d-block py-1 mt-3 mx-auto px-5"
          >
            <!-- <router-link
              to="system-checker"
              style="color: white; font-size: 25px; text-decoration: none"
            >
              Laboratory</router-link
            > -->
            <h1 style="color: white; font-size: 25px; text-decoration: none">
              Laboratory
            </h1>
          </button>
          <p class="p-4">
            Receive a comprehensive analysis based on your chosen match.
          </p>
        </div>
        <div class="col-md-4 col-sm-12">
          <img src="../../assets/consultation-icons/Record.svg" width="50" />
          <button
            type="button"
            class="btn btn-secondary d-block py-1 w-auto mt-3 mx-auto px-5"
          >
            <!-- <router-link
              to="system-checker"
              style="color: white; font-size: 25px; text-decoration: none"
            >
              Treatment</router-link
            > -->
            <h1 style="color: white; font-size: 25px; text-decoration: none">
              Treatment
            </h1>
          </button>
          <p class="p-4">Discover the interactions between various drugs.</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      selectedConsultation: null,
    };
  },
  methods: {
    handleSelectConsultation(consultation) {
      this.selectedConsultation = consultation;
    },
    goBack(){
      this.$router.back();
    }
  },
};
</script>

<style scoped>
.page-title {
  font-size: 100px;
  color: #000;
  font-weight: bold;
  line-height: 60px;
  padding-top: 100px;
}

.content-wrap {
  margin-top: 100px;
}

.content-padding {
  padding-top: 100px;
}
.back-wrap {
  display: flex;
  align-items: center;
  margin-left: 1rem;
  padding: 20px;
  cursor: pointer;
}

.back-wrap img {
  height: 30px;
}
</style>
