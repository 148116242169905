<template>
  <div class="container-fluid">
    <div @click="goBack()" class="back-wrap">
      <img src="../../assets/consultation-icons/back.svg" width="60" />
      Consultations
    </div>

    <div class="w-100 py-3 d-flex flex-column align-items-center">
      <div class="d-flex">
        <h5>Checking Information</h5>
        <h5 class="mx-3">LOADER</h5>
      </div>
      <div class="w-50 rounded-4 gap-3 overflow-hidden">
        <div
          @click="handlePatientSection()"
          style="background-color: #e6e6e6; cursor: pointer"
          class="py-4 px-1 d-flex w-100 rounded-bottom-3 justify-content-between px-4 align-items-center"
          :class="[
            patientInfoSection === true ? 'img-rotate' : 'img-rotate-undo',
          ]"
        >
          <h4 class="section-title">Patient Information</h4>
          <img
            src="../../assets/consultation-icons/Drop-down.svg"
            style="height: 20px; width: 20px"
            alt=""
          />
        </div>
        <div
          class="w-100"
          :class="[
            patientInfoSection === true ? 'content-active' : 'content-inactive',
          ]"
        >
          <div
            :class="[patientInfoSection === true ? 'child' : 'child-inactive']"
          >
            <form class="container mt-4 p-4 form-container">
              <div class="row mb-4">
                <!-- First Row -->
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="field1">Surname*</label>
                    <input
                      v-model="patientInformation.surname"
                      type="text"
                      class="form-control"
                      id="field1"
                      pattern="[A-Za-z ]+"
                      title="Please enter alphabetic characters and spaces only"
                      required
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="field2">Other Names*</label>
                    <input
                      v-model="patientInformation.otherName"
                      type="text"
                      class="form-control"
                      id="field2"
                      pattern="[A-Za-z ]+"
                      title="Please enter alphabetic characters and spaces only"
                      required
                    />
                  </div>
                </div>
              </div>
              <!-- Second Row -->
              <div class="row mb-4">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="field3">Gender*</label>
                    <select
                      v-model="patientInformation.gender"
                      class="form-control form-select"
                    >
                      <option disabled>Select Gender</option>
                      <option value="Male">MALE</option>
                      <option value="Female">FEMALE</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="field4">Age*</label>
                    <input
                      v-model="patientInformation.age"
                      type="number"
                      class="form-control"
                      id="field4"
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="field6">Occupation</label>
                    <input
                      v-model="patientInformation.occupation"
                      type="text"
                      class="form-control"
                      id="field6"
                    />
                  </div>
                </div>
              </div>
              <div class="row mb-4">
                <!-- First Row -->
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="field1">Ethnicity*</label>

                    <input
                      v-model="this.patientInformation.ethnicity"
                      @input="filterEthnicity"
                      type="text"
                      class="form-control"
                      id="field1"
                    />
                    <ul
                      v-if="showEthnicDropdown"
                      :class="[
                        filteredEthnicity.length ? 'dropdown' : 'dropdown-2',
                      ]"
                    >
                      <li
                        v-for="ethnicGroup in filteredEthnicity"
                        :key="ethnicGroup"
                        @click="selectEthnic(ethnicGroup)"
                      >
                        {{ ethnicGroup }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="field2">Location (State)*</label>
                    <select
                      v-model="patientInformation.location"
                      class="form-control form-select"
                    >
                      <option value="Parent">Select State</option>
                      <option
                        class="select-option"
                        v-for="(item, index) in statesArray"
                        :value="item"
                        :key="index"
                      >
                        <p>{{ item }}</p>
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="field1">Address</label>
                    <input
                      v-model="patientInformation.address"
                      type="text"
                      class="form-control"
                      id="field15"
                    />
                  </div>
                </div>
              </div>
              <!-- Third Row -->
              <div class="row mb-4">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="field4">Temperature ( &deg;C )</label>
                    <input
                      v-model="patientInformation.temperature"
                      type="number"
                      class="form-control"
                      id="field7"
                    />
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="field4">Height(m)</label>
                    <input
                      v-model="patientInformation.height"
                      type="number"
                      class="form-control"
                      id="field8"
                      @input="calculateBMI"
                    />
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="field5">Weight(kg)</label>
                    <input
                      v-model="patientInformation.weight"
                      type="number"
                      class="form-control"
                      id="field9"
                      @input="calculateBMI"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group disable-input">
                    <label for="field6">BMI</label>
                    <input
                      v-model="patientInformation.bmi"
                      type="number"
                      class="form-control disable-bg"
                      readonly
                    />
                  </div>
                </div>
              </div>

              <!-- Fourth Row -->
              <div class="row mb-4">
                <div class="col-md-12">
                  <multiselect
                    v-model="selectedDrugs"
                    :multiple="true"
                    :options="medicineData"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder="Select Medicine"
                    label="DrugName"
                    track-by="DrugName"
                    ref="multiselectRef2"
                    @select="focusMultiselectDropdown2"
                  ></multiselect>
                </div>
              </div>

              <div class="row mb-4">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="field3">Systolic*</label>
                    <input
                      v-model="patientInformation.systolic"
                      type="number"
                      class="form-control"
                      id="field12"
                      @input="formatBP"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="field3">Diastolic*</label>
                    <input
                      v-model="patientInformation.diastolic"
                      type="number"
                      class="form-control"
                      id="field13"
                      @input="formatBP"
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="field4">BP*</label>
                    <input
                      v-model="patientInformation.bp"
                      type="text"
                      class="form-control disable-bg"
                      id="field14"
                      readonly
                    />
                  </div>
                </div>
              </div>

              <!-- Fifth Row -->
              <div class="row mb-4">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="field1">Allergies</label>
                    <input
                      v-model="patientInformation.allergies"
                      type="text"
                      class="form-control"
                      id="field15"
                    />
                  </div>
                </div>
                <div class="col-md-12 mt-3">
                  <div class="form-group">
                    <label for="field2">Medical Condition</label>
                    <input
                      v-model="patientInformation.medical_condition"
                      type="text"
                      class="form-control"
                      id="field16"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div
          @click="handleFamilySection()"
          style="background-color: #e6e6e6; cursor: pointer"
          class="py-4 px-1 d-flex w-100 rounded-3 justify-content-between px-4 align-items-center"
          :class="[
            familyHistorySection === true ? 'img-rotate' : 'img-rotate-undo',
          ]"
        >
          <h4 class="section-title">Family History</h4>
          <img
            src="../../assets/consultation-icons/Drop-down.svg"
            style="height: 20px; width: 20px"
            alt=""
          />
        </div>
        <div
          class="w-100"
          :class="[
            familyHistorySection === true
              ? 'content-active'
              : 'content-inactive',
          ]"
        >
          <div
            :class="[
              familyHistorySection === true ? 'child' : 'child-inactive',
            ]"
          >
            <form class="container mt-4 p-4 form-container">
              <div
                v-for="(familyItem, index) in familyHistory"
                :key="index"
                class="row mb-1"
              >
                <!-- First Row -->
                <div class="col-md-8">
                  <div class="form-group">
                    <label for="field1">Medical Condition*</label>
                    <input
                      v-model="familyItem.medical_condition"
                      type="text"
                      class="form-control"
                      id="field17"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Relationship</label>
                    <select
                      v-model="familyItem.relationship"
                      class="form-control form-select"
                    >
                      <option value="Parent">Parent</option>

                      <option value="Child">Child</option>
                      <option value="Sibling">Sibling</option>
                      <option value="Grand Parent">Grand Parent</option>
                      <option value="Grand Child">Grand Child</option>
                    </select>
                  </div>
                </div>
                <div class="mb-4 d-flex justify-content-end">
                  <button
                    @click="removeFamilyHistory(familyItem)"
                    type="button"
                    class="btn btn-link text-danger"
                  >
                    Remove
                  </button>
                  <button type="button" class="btn btn-link">Edit</button>
                </div>
              </div>

              <button
                @click="addFamilyHistory()"
                type="button"
                class="btn btn-secondary py-3 mx-auto mb-3 mt-5 w-100"
              >
                Add Condition
              </button>
            </form>
          </div>
        </div>
        <div
          @click="handleSymptomsSection()"
          style="background-color: #e6e6e6; cursor: pointer"
          class="py-4 px-1 d-flex w-100 rounded-3 justify-content-between px-4 align-items-center"
          :class="[symptomsSection === true ? 'img-rotate' : 'img-rotate-undo']"
        >
          <h4 class="section-title">Symptoms</h4>
          <img
            src="../../assets/consultation-icons/Drop-down.svg"
            style="height: 20px; width: 20px"
            alt=""
          />
        </div>
        <div
          class="w-100"
          :class="[
            symptomsSection === true ? 'content-active' : 'content-inactive',
          ]"
        >
          <div :class="[symptomsSection === true ? 'child' : 'child-inactive']">
            <form class="container mt-4 p-4 form-container">
              <div
                v-for="(symptomItem, index) in symptoms"
                :key="index"
                class="row mb-1"
              >
                <!-- First Row -->
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="field1">Symptoms*</label>
                    <input
                      v-model="symptomItem.symptom"
                      type="text"
                      class="form-control"
                      id="field17"
                      @input="filterSymptoms(symptomItem)"
                    />
                    <ul
                      v-if="symptomItem.showDropdown"
                      :class="[
                        Object.keys(filteredContent).length
                          ? 'symptom-dropdown'
                          : 'symptom-dropdown-2',
                      ]"
                    >
                      <li
                        v-for="(symptom, index) in filteredSymptom(
                          symptomItem.symptom
                        )"
                        :key="index"
                      >
                        <h1
                          @click="handleSelectSymptom(symptomItem, symptom)"
                          class="symptom-item"
                        >
                          {{ symptom }}
                        </h1>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="field2">Duration (days)</label>
                    <input
                      v-model="symptomItem.duration"
                      type="number"
                      class="form-control"
                      id="field18"
                    />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label>Severity</label>
                    <select
                      v-model="symptomItem.severity"
                      class="form-control form-select"
                    >
                      <option value="Mild">Mild</option>
                      <option value="Moderate">Moderate</option>
                      <option value="Severe">Severe</option>
                    </select>
                  </div>
                </div>
                <div
                  v-if="symptoms.length > 1"
                  class="mb-4 d-flex justify-content-end"
                >
                  <button
                    @click="removeSymptoms(symptomItem)"
                    type="button"
                    class="btn btn-link text-danger"
                  >
                    Remove
                  </button>
                  <button type="button" class="btn btn-link">Edit</button>
                </div>
              </div>

              <button
                @click="addSymptoms()"
                type="button"
                class="btn btn-secondary py-3 mx-auto mb-3 mt-5 w-100"
              >
                Add Symptoms
              </button>
            </form>
          </div>
        </div>
        <div
          @click="handleSignsSection()"
          style="background-color: #e6e6e6; cursor: pointer"
          class="py-4 px-1 d-flex w-100 rounded-3 justify-content-between px-4 align-items-center"
          :class="[signsSection === true ? 'img-rotate' : 'img-rotate-undo']"
        >
          <h4 class="section-title">Signs</h4>
          <img
            src="../../assets/consultation-icons/Drop-down.svg"
            style="height: 20px; width: 20px"
            alt=""
          />
        </div>
        <div
          class="w-100"
          :class="[
            signsSection === true ? 'content-active' : 'content-inactive',
          ]"
        >
          <div :class="[signsSection === true ? 'child' : 'child-inactive']">
            <form class="container mt-4 p-4 form-container">
              <div
                v-for="(signItem, index) in signs"
                :key="index"
                class="row mb-1"
              >
                <!-- First Row -->
                <div class="col-md-9">
                  <div class="form-group">
                    <label>Sign*</label>
                    <input
                      v-model="signItem.sign"
                      type="text"
                      class="form-control"
                      id="field18"
                    />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label>Severity</label>
                    <select
                      v-model="signItem.severity"
                      class="form-control form-select"
                    >
                      <option value="Mild">Mild</option>
                      <option value="Moderate">Moderate</option>
                      <option value="Severe">Severe</option>
                    </select>
                  </div>
                </div>

                <div class="mb-4 d-flex justify-content-end">
                  <button
                    @click="removeSign(signItem)"
                    type="button"
                    class="btn btn-link text-danger"
                  >
                    Remove
                  </button>
                  <button type="button" class="btn btn-link">Edit</button>
                </div>
              </div>

              <button
                @click="addSign()"
                type="button"
                class="btn btn-secondary py-3 mx-auto mb-3 mt-5 w-100"
              >
                Add Signs
              </button>
            </form>
          </div>
        </div>
        <div
          @click="handleAdditionalSection()"
          style="background-color: #e6e6e6; cursor: pointer"
          class="py-4 px-1 d-flex w-100 rounded-3 justify-content-between px-4 align-items-center"
          :class="[
            additionalInfoSection === true ? 'img-rotate' : 'img-rotate-undo',
          ]"
        >
          <h4 class="section-title">Additional Information</h4>
          <img
            src="../../assets/consultation-icons/Drop-down.svg"
            style="height: 20px; width: 20px"
            alt=""
          />
        </div>
        <div
          class="w-100"
          :class="[
            additionalInfoSection === true
              ? 'content-active'
              : 'content-inactive',
          ]"
        >
          <div
            :class="[
              additionalInfoSection === true ? 'child' : 'child-inactive',
            ]"
          >
            <form class="container mt-4 p-4 form-container">
              <div class="row mb-1">
                <!-- First Row -->
                <div class="col-md-8">
                  <div class="form-group">
                    <label for="field1">Recent Exposure</label>
                    <input
                      v-model="additionalInfo.recentExposure.exposure"
                      type="text"
                      class="form-control"
                      id="field17"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="field2">Duration</label>
                    <input
                      v-model="additionalInfo.recentExposure.duration"
                      type="text"
                      class="form-control"
                      id="field18"
                    />
                  </div>
                </div>
              </div>
              <div class="row mb-1">
                <!-- First Row -->
                <div class="col-md-8">
                  <div class="form-group">
                    <label for="field1">Social Activity</label>
                    <input
                      v-model="additionalInfo.socialActivity.activity"
                      type="text"
                      class="form-control"
                      id="field17"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="field2">Duration</label>
                    <input
                      v-model="additionalInfo.socialActivity.duration"
                      type="text"
                      class="form-control"
                      id="field18"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <button
          type="button"
          class="btn btn-secondary py-3 mx-auto mt-2 w-100 d-flex justify-content-center"
          @click="suggestPossibleCondition()"
        >
          <div class="w-100 d-flex justify-content-center">
            <div class="d-flex justify-content-center align-items-center">
              <div
                v-if="loading"
                class="spinner-border spinner-height mx-3"
                role="status"
              ></div>
            </div>
            {{
              loading === true
                ? "Fetching Diagnosis"
                : "Suggest Possible Medical Conditions"
            }}
          </div>
        </button>
        <div class="mb-3 note d-flex justify-content-center">
          NB: The more information provided will determine the accuracy and
          confidence of diagnosis
        </div>
      </div>
      <div v-if="loading" class="mt-3 d-flex flex-column align-items-center">
        <div
          class="spinner-border spinner-loader-height mx-3"
          role="status"
        ></div>
        <h2 class="my-2 loading-text">
          Please wait while we fetch suggested diagnosis ... ... ..
        </h2>
      </div>
      <div
        v-if="showMessageText"
        class="mt-3 w-50 p-5 d-flex justify-content-center align-items-center save-notification"
      >
        <h3>Patient information saved successfully</h3>
      </div>

      <div v-for="(item, index) in diagnosis" :key="index" class="w-50 mt-4">
        <div class="result-item">
          <div class="d-flex justify-content-between align-items-center py-2">
            <span v-if="diagnosis[0] === item">Best Match</span>
            <span>{{ item.confidenceScore }}%</span>
          </div>
          <h1>
            {{ item.diagnosis === undefined ? item.condition : item.diagnosis }}
          </h1>
          <h3>Clinical Evidence</h3>
          <div class="py-1">
            <p>
              {{ item.justification }}
            </p>
          </div>
          <button>See Treatment Options</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Sidebar from "../layouts/SideBar.vue";
// import axios from "axios";
import medicineData from "./medicine-data.json";
import statesData from "./states.json";
import ethnicData from "./ethnic-data.json";
import symptomsData from "./symptoms.json";
import ApiServices from "@/service/ApiServices";
import Multiselect from "vue-multiselect";

export default {
  components: {
    Multiselect,
  },

  data() {
    return {
      activeButton: "system-checker", // Set the active button for the sidebar
      activeSection: null,
      patientInfoSection: true,
      familyHistorySection: false,
      symptomsSection: false,
      signsSection: false,
      additionalInfoSection: false,
      failedRequest: false,
      patientInformation: {
        surname: null,
        otherName: null,
        gender: "Not Stated",
        age: null,
        ethnicity: null,
        location: null,
        address: null,
        occupation: null,
        temperature: null,
        height: 0,
        weight: 0,
        bmi: 0,
        medications: null,
        systolic: null,
        diastolic: null,
        bp: null,
        allergies: null,
        medical_condition: null,
      },
      familyHistory: [],
      symptoms: [],
      signs: [],
      additionalInfo: {
        recentExposure: {
          exposure: null,
          duration: null,
        },
        socialActivity: {
          activity: null,
          duration: null,
        },
      },
      diagnosis: [],
      loading: false,
      showDropdown: false,
      showEthnicDropdown: false,
      searchTerm: "",
      ethnicTerm: "",
      selectedDrugs: [],
      showMessageText: false,
      statesArray: statesData,
      showSymptomsDropdown: false,
      minWeight: 2.04,
      maxWeight: 650,
      minHeight: 0.5,
      maxHeight: 2.72,
      minTemperature: 14,
      maxTemperature: 47,
      filteredContent: {},
      medicineData,
      repeatFlow: true,
    };
  },

  watch: {
    "patientInformation.surname"(newVal) {
      if (!/^[A-Za-z\s]*$/.test(newVal)) {
        this.patientInformation.surname = newVal.replace(/[^A-Za-z\s]/g, "");
      }
    },
    "patientInformation.otherName"(newVal) {
      if (!/^[A-Za-z\s]*$/.test(newVal)) {
        this.patientInformation.otherName = newVal.replace(/[^A-Za-z\s]/g, "");
      }
    },
    "patientInformation.occupation"(newVal) {
      if (!/^[A-Za-z\s]*$/.test(newVal)) {
        this.patientInformation.occupation = newVal.replace(/[^A-Za-z\s]/g, "");
      }
    },
  },
  computed: {
    filteredDrugs() {
      return medicineData.filter((drug) =>
        drug.DrugName.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    },

    filteredEthnicity() {
      return ethnicData.filter((ethnic) =>
        ethnic
          .toLowerCase()
          .includes(this.patientInformation.ethnicity.toLowerCase())
      );
    },
    filteredSymptom() {
      return (searchQuery) => {
        const query = searchQuery.toLowerCase();
        const filteredContent = symptomsData.filter((ethnic) =>
          ethnic.toLowerCase().includes(query)
        );
        return filteredContent;
      };
    },
    filteredSymptoms() {
      console.log(symptomsData);
      return (searchQuery) => {
        const query = searchQuery.toLowerCase();
        const filtered = {};
        for (const category in symptomsData.symptoms) {
          const subCategories = symptomsData.symptoms[category];
          const filteredSubCategories = {};
          for (const subCategory in subCategories) {
            const symptoms = subCategories[subCategory].filter((symptom) =>
              symptom.toLowerCase().includes(query)
            );
            if (symptoms.length > 0) {
              filteredSubCategories[subCategory] = symptoms;
            }
          }
          if (Object.keys(filteredSubCategories).length > 0) {
            filtered[category] = filteredSubCategories;
          }
        }
        console.log(Object.keys(filtered).length);
        this.filteredContent = filtered;
        return filtered;
      };
    },
  },
  methods: {
    goBack() {
      this.$router.back();
    },

    toggleSidebar() {
      // Implement your sidebar toggling logic if needed
    },
    filterDrugs() {
      this.showDropdown = this.searchTerm.length > 0;
    },
    filterEthnicity() {
      this.showEthnicDropdown = this.patientInformation.ethnicity.length > 0;
    },
    filterSymptoms(symptomItem) {
      if (symptomItem.symptom.length > 0) {
        symptomItem.showDropdown = true;
      } else {
        symptomItem.showDropdown = false;
      }
    },
    selectDrug(drug) {
      if (!this.selectedDrugs.includes(drug.DrugName)) {
        this.selectedDrugs.push(drug.DrugName);
      }
      this.searchTerm = "";
      this.showDropdown = false;
    },
    selectEthnic(ethnic) {
      let parts = ethnic.split(" - ");
      console.log(parts[0]);
      this.patientInformation.ethnicity = parts[0];
      this.showEthnicDropdown = false;
    },
    removeDrug(drug) {
      this.selectedDrugs = this.selectedDrugs.filter((item) => item !== drug);
    },

    focusMultiselectDropdown2() {
      this.$nextTick(() => {
        const multiselectInstance = this.$refs.multiselectRef2;
        const inputElement = multiselectInstance.$refs.search;
        inputElement.focus();
      });
    },

    calculateBMI() {
      // if (this.patientInformation.height < this.minHeight) {
      //   this.patientInformation.height = this.minHeight;
      // } else if (this.patientInformation.height > this.maxHeight) {
      //   this.patientInformation.height = this.maxHeight;
      // }

      // if (this.patientInformation.weight < this.minWeight) {
      //   this.patientInformation.weight = this.minWeight;
      // } else if (this.patientInformation.weight > this.maxWeight) {
      //   this.patientInformation.weight = this.maxWeight;
      // }
      const { height, weight } = this.patientInformation;

      console.log(this.patientInformation);

      if (height && weight) {
        // BMI formula: weight (kg) / (height (m) * height (m))
        const heightInMeters = height; // Convert height to meters
        const bmi = (weight / (heightInMeters * heightInMeters)).toFixed(2);

        // Update the info object with the calculated BMI
        this.patientInformation.bmi = bmi;
      } else {
        // If either height or weight is not provided, reset BMI to null
        this.patientInformation.bmi = null;
      }
    },
    formatBP() {
      const { systolic, diastolic } = this.patientInformation;

      if (systolic && diastolic) {
        // Update the info object with the calculated BMI
        this.patientInformation.bp = `${systolic}/${diastolic} mmHg`;
      } else {
        // If either height or weight is not provided, reset BMI to null
        this.patientInformation.bp = null;
      }
    },
    handleSelectSection(section) {
      if (this.activeSection === null) {
        this.activeSection = section;
      } else {
        this.activeSection = null;
      }
    },
    handlePatientSection() {
      this.patientInfoSection = !this.patientInfoSection;
    },
    handleFamilySection() {
      this.familyHistorySection = !this.familyHistorySection;
    },
    handleSymptomsSection() {
      this.symptomsSection = !this.symptomsSection;
    },
    handleSignsSection() {
      this.signsSection = !this.signsSection;
    },
    handleAdditionalSection() {
      this.additionalInfoSection = !this.additionalInfoSection;
    },
    handleSelectSymptom($model, symptom) {
      $model.symptom = symptom;
      $model.showDropdown = false;
    },

    addSymptoms() {
      this.symptoms.push({
        symptom: "",
        duration: null,
        severity: "Mild",
        id: new Date().getTime(),
        showDropdown: false,
      });
    },

    removeSymptoms(symptom) {
      this.symptoms = this.symptoms.filter((item) => item.id !== symptom.id);
    },

    addFamilyHistory() {
      this.familyHistory.push({
        medical_condition: null,
        relationship: "Parent",
        id: new Date().getTime(),
      });
    },

    removeFamilyHistory(familyItem) {
      if (this.familyHistory.length > 1) {
        this.familyHistory = this.familyHistory.filter(
          (item) => item.id !== familyItem.id
        );
      }
    },

    addSign() {
      this.signs.push({
        sign: null,
        severity: "Mild",
        id: new Date().getTime(),
      });
    },

    removeSign(signItem) {
      this.signs = this.signs.filter((item) => item.id !== signItem.id);
    },

    trimContent(text) {
      // Find the index of the first '{' character
      const startIndex = text.indexOf("{");

      // Find the index of the last '}' character
      const endIndex = text.lastIndexOf("}");

      // Check if both '{' and '}' are found
      if (startIndex === -1 || endIndex === -1) {
        console.error("Invalid JSON format: Missing '{' or '}'");
        return null;
      }

      // Extract the JSON substring
      const jsonContent = text.substring(startIndex, endIndex + 1);

      try {
        // Parse the JSON content
        const parsedJSON = JSON.parse(jsonContent);

        // Return the parsed JSON object
        return parsedJSON;
      } catch (error) {
        // Handle JSON parsing errors
        console.error("Error parsing JSON:", error);
        return null; // or handle the error in a way appropriate for your use case
      }
    },

    showMessage() {
      this.showMessageText = true;
      setTimeout(() => {
        this.showMessageText = false;
      }, 4000);
    },

    async suggestPossibleCondition() {
  try {
    this.repeatFlow = true;
    this.patientInformation.medications = this.selectedDrugs ? this.selectedDrugs.join(",") : "";
    let symptomText = "";
    let signText = "";
    let familyText = "";

    // Construct symptom text
    if (Array.isArray(this.symptoms)) {
      this.symptoms.forEach((symptom, index) => {
        symptomText += `${index > 0 ? ", " : ""}${symptom.symptom} lasting for ${symptom.duration} with severity ${symptom.severity}`;
      });
    }

    // Construct sign text
    if (Array.isArray(this.signs)) {
      this.signs.forEach((sign, index) => {
        signText += `${index > 0 ? ", " : ""}${sign.sign} lasting for ${sign.duration} with severity ${sign.severity}`;
      });
    }

    // Construct family history text
    if (Array.isArray(this.familyHistory)) {
      this.familyHistory.forEach((history, index) => {
        familyText += `${index > 0 ? ", " : ""}${history.relationship} who has ${history.medical_condition} condition`;
      });
    }

    const promptData = {
      gender: this.patientInformation.gender,
      age: this.patientInformation.age,
      temperature: this.patientInformation.temperature,
      bmi: this.patientInformation.bmi,
      bp: this.patientInformation.bp,
      medical_condition: this.patientInformation.medical_condition,
      familyText: familyText,
      symptomText: symptomText,
      signText: signText,
      recentExposure: this.additionalInfo.recentExposure ? this.additionalInfo.recentExposure.exposure : undefined,
      exposureDuration: this.additionalInfo.recentExposure ? this.additionalInfo.recentExposure.duration : undefined,
      socialActivity: this.additionalInfo.socialActivity ? this.additionalInfo.socialActivity.activity : undefined,
      activityDuration: this.additionalInfo.socialActivity ? this.additionalInfo.socialActivity.duration : undefined,
    };

    // Remove null values from promptData
    Object.keys(promptData).forEach(key => {
      if (promptData[key] === null || promptData[key] === undefined || promptData[key] === "") {
        delete promptData[key];
      }
    });

    const prompt = `As a decision support system for primary healthcare workers in low-income countries, analyze the patients bio-data. ${
      promptData.gender !== "Not Stated" ? ` Patient is a ${promptData.gender}` : ""
    }${promptData.age !== undefined ? ` aged ${promptData.age}` : ""}${
      promptData.temperature !== undefined ? ` with temperature of ${promptData.temperature}` : ""
    }${promptData.bmi !== 0 ? `, Body max index of ${promptData.bmi}` : ""}${
      promptData.bp !== undefined ? ` and Blood pressure of ${promptData.bp}` : ""
    }. ${
      promptData.medical_condition !== undefined ? `Patient has the following existing medical conditions ${promptData.medical_condition}` : ""
    } ${
      promptData.familyText !== "" ? ` and family history ${promptData.familyText}` : ""
    }. ${
      promptData.symptomText !== "" ? ` The patient complains about ${promptData.symptomText}` : ""
    } ${
      promptData.signText !== "" ? ` and exhibits signs of ${promptData.signText}` : ""
    }, ${
      promptData.recentExposure !== undefined ? ` with recent exposure and social activities like ${promptData.recentExposure} for duration of ${promptData.exposureDuration}` : ""
    }${
      promptData.socialActivity !== undefined ? ` and ${promptData.socialActivity} for duration of ${promptData.activityDuration}` : ""
    }. Output three possible diagnoses, each with a certainty score and a concise justification for each potential diagnosis in JSON key-value pairs. Json Should have the following keys: diagnoses, condition, justification, confidenceScore`;

    const data = {
      text: prompt,
      system_prompt: "you are a decision support system exclusively using JSON key-value pairs to assist primary healthcare workers in low-income countries. All outputs must adhere strictly to JSON format without any filler words, introductions, or warnings",
      temperature: 1,
      max_new_tokens: 500,
      min_new_tokens: -1,
    };
    this.diagnosis = [];

    const api = new ApiServices();
    this.loading = true;

    while (this.repeatFlow) {
      try {
        if (promptData.symptomText !== "" && (promptData.signText !== "" || promptData.symptomText !== "")) {
          this.loading = true;
          const tempData = await api.getDiagnosis(data);
          const temp = this.trimContent(tempData.data.response);

          if (temp !== null) {
            this.diagnosis = temp.diagnoses;
            this.diagnosis.sort((a, b) => parseInt(b.confidenceScore) - parseInt(a.confidenceScore));

            this.patientInfoSection = false;
            this.familyHistorySection = false;
            this.symptomsSection = false;
            this.signsSection = false;
            this.additionalInfoSection = false;
            this.loading = false;
            this.failedRequest = false;

            const patientData = {
              diagnosis: temp.diagnoses,
              signs: this.signs,
              Additional_Info: this.additionalInfo,
              family_history: this.familyHistory,
              symptoms: this.symptoms,
              bio_data: this.patientInformation,
            };

            console.log(patientData);

            const patientProfile = await api.savePatient(patientData);
            console.log(patientProfile, "patientProfile");
            if (patientProfile.status === 201) {
              this.showMessage();
            }
            this.loading = false;
            this.repeatFlow = false; // Set repeatFlow to false to exit the loop
          } else {
            this.loading = false;
            // If temp is null, repeat the flow by continuing the loop
            continue;
          }
        } else {
          this.repeatFlow = false; // Set repeatFlow to false to exit the loop
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.repeatFlow = false; // Set repeatFlow to false to exit the loop
      }
    }
  } catch (error) {
    console.error("Error in suggestPossibleCondition:", error);
    this.loading = false;
    this.repeatFlow = false; // Ensure repeatFlow is set to false on top-level error
  }
}

  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped>
.result {
  line-height: 2.5rem;
}
.section-title {
  margin-left: 50px;
  font-size: 30px;
  user-select: none;
}
.img-rotate img {
  transform: rotate(180deg);
  -webkit-transition: transform 1s ease-out;
  -moz-transition: transform 1s ease-out;
  -o-transition: transform 1s ease-out;
  transition: transform 1s ease-out;
}

.img-rotate-undo img {
  transform: rotate();
  -webkit-transition: transform 1s ease-out;
  -moz-transition: transform 1s ease-out;
  -o-transition: transform 1s ease-out;
  transition: transform 1s ease-out;
}
.content-active {
  height: auto;
  display: block;
  background-color: #f3f3f3;
  padding: 20px 10px;
  margin-top: -8px;
  transition: 0.35s;
  transition-timing-function: ease-in;
  margin-bottom: 30px;
}

.content-inactive {
  height: 0;
  transition-timing-function: ease-in;
  transition: 0.25s;
  margin-bottom: 30px;
}

.child {
  display: block;
  transition: none;
}
.child-inactive {
  /* opacity: 0; */
  display: none;
  transition: none;
}

.result-item {
  width: 100%;
  height: fit-content;
  padding: 20px 40px;
  background-color: #b3b3b3;
  border-radius: 10px;
}
.result-item button {
  padding: 10px 20px;
  outline: none;
  border: none;
  border-radius: 10px;
  background-color: #808080;
  color: #fff;
}

.spinner-height {
  height: 2rem;
  width: 2rem;
  color: #fff;
}

.spinner-loader-height {
  height: 10rem;
  width: 10rem;
  color: #21922a;
}

.loading-text {
  animation: pulse 5s infinite;
  font-size: 20px;
}

@keyframes pulse {
  0% {
    color: #808080;
  }
  50% {
    opacity: 0;
  }
  100% {
    color: #808080;
  }
}
.danger-bg {
  background-color: #f7cece;
  padding: 2rem 0;
  width: 50%;
  border-radius: 10px;
}
.disable-bg {
  background-color: #b3b3b3;
}
.disable-input input:focus {
  background-color: #b3b3b3;
}

.autocomplete {
  position: relative;
  display: inline-block;
}

.symptom-dropdown {
  position: absolute;
  background-color: #f1f1f1;
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  width: 400px;
  z-index: 100;
  margin-top: 5px;
}

.symptom-dropdown-2 {
  position: absolute;
  background-color: #f1f1f1;
  list-style-type: none;
  padding: 0;
  margin: 0;
  /* border: 1px solid #ccc; */
  max-height: 150px;
  overflow-y: auto;
  width: 400px;
  z-index: 100;
  margin-top: 5px;
}

.dropdown {
  position: absolute;
  background-color: #f1f1f1;
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  width: 300px;
  z-index: 100;
}

.dropdown-3 {
  position: absolute;
  background-color: #f1f1f1;
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  width: 600px;
  z-index: 100;
  padding: 10px;
}

.dropdown-2 {
  position: absolute;
  background-color: #f1f1f1;
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 150px;
  overflow-y: auto;
  width: 300px;
  z-index: 100;
}

.dropdown li {
  padding: 8px 12px;
  cursor: pointer;
}

.symptom-dropdown li {
  /* padding: 10px 10px; */
  cursor: pointer;
}

.symptom-item {
  padding: 5px 0;
  cursor: pointer;
  font-size: 18px;
}

.symptom-item:hover {
  color: #21922a;
}

.dropdown li:hover {
  background-color: #ddd;
}

.save-notification {
  border-radius: 10px;
  background-color: #21922a;
}
.save-notification h3 {
  font-size: 24px;
}
.drug-item {
  border-radius: 5px;
  position: relative;
  z-index: 0;
  background-color: #fff;
}
.close {
  position: absolute;
  top: -8px;
  right: -3px;
  margin: 0;
  background-color: rgb(238, 164, 164);
  padding: 1px 5px;
  border-radius: 50px;
  font-size: 12px;
  cursor: pointer;
}
.selected-drug {
  border: 1px solid #ddd;
  border-radius: 4px;
}
.note {
  font-size: 14px;
}
.category {
  font-weight: bold;
  font-size: 16px;
}
.sub-category {
  font-weight: 700;
  font-size: 15px;
  text-decoration: none;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.symptom {
  font-size: 14px;
  padding: 0;
  margin: 0;
}
.symptom:hover {
  color: #21922a;
}
.symptom-ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sub-category-group {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.back-wrap {
  display: flex;
  align-items: center;
  margin-left: 1rem;
  padding: 20px;
  cursor: pointer;
}

.back-wrap img {
  height: 30px;
}
</style>
