import { createApp } from 'vue'; // Import createApp from Vue 3
import App from './App.vue';
import router from './router';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-icons/font/bootstrap-icons.css';


const app = createApp(App);

app.use(router);


app.mount('#app'); // Use app.mount() to mount the app to an element
