<template>
  <section>
    <consultationSection />
  </section>
</template>

<script>
import "../../../styles/home.scss";
import { defineComponent } from "vue";
import consultationSection from "../../../components/consultation-layout/consultationSection.vue";

export default defineComponent({
  name: "Home-Page",
  components: {
    consultationSection,
  },
  data() {
    return {};
  },
});
</script>
