import { createRouter, createWebHistory } from 'vue-router'; // Import Vue Router functions

import { route as HOMEPAGE_ROUTE } from '@/modules/homepage';

const routes = [
  ...HOMEPAGE_ROUTE,
  {
    // will match everything
    path: '/:catchAll(.*)',
    component: () =>
      import(/* webpackChunkName: "404" */ '@/modules/homepage/views/404.vue')
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
